<template>
  <div class="i_page">
    <!-- 头部 -->
    <new-header :config="headerConfig"></new-header>
    <!-- 表單 -->
    <div class="i_content">
      <div class="address_top">
        <div class="a_item">
          <div class="address_t">公司地址：</div>
          <div>{{ configData.shares_sales_receiver_address }}</div>
        </div>
        <div class="a_item">
          <div class="address_t">公司收件人：</div>
          <div>{{ configData.shares_sales_receiver }}</div>
        </div>
        <div class="a_item">
          <div class="address_t">公司联系方式：</div>
          <div>{{ configData.shares_sales_receiver_mobile }}</div>
        </div>
      </div>
      <div class="i_from_box">
        <div class="i_from">
          <div class="i_from_item">
            <div style="display:flex;position: relative;">
              <Input :config="alterationFrom.s_order_sn" />
              <div class="comfimBtn" @click="shipmentsBtn">确认</div>
            </div>
            <Input :config="alterationFrom.s_company" />
            <Input :config="alterationFrom.s_message" />


            <div class="price_info">
              <div>服务费：{{ orderData.order_amount || '' }}</div>
              <div class="order_btn" @click="orderBtn">订单抵扣 ></div>
            </div>
            <!-- 选择订单抵扣 -->
            <div class="order_discoun" v-if="orderData.ordergoods">
              <div class="discoun">
                <img class="discoun_img" :src="orderData.ordergoods.goods_image" />
                <div class="text_info">
                  <div class="title">{{ orderData.ordergoods.goods_name }}</div>
                  <div>订单编号：{{ orderData.order_sn }}</div>
                  <div>订单时间：{{ orderData.add_time }}</div>
                  <div>订单金额：{{ orderData.order_amount }}</div>
                </div>
              </div>
              <div class="status_text">已完成</div>
            </div>

            <div class="submit_btn_i" @click="nextBtnBtn">提交</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 提示弹窗 -->
    <new-dialog :config="dialogConfig" @confirm="dialogConfirmChange">
      <template slot="text">
        <div>请确认信息，数据将转移给用户：</div>
        <div style="margin-bottom: 10px;">
          <span class="message_text1">{{ configData.shares_sales_receiver }}</span>
          是否提交
        </div>
        <div class="message_text1">物流单号：{{ alterationFrom.s_order_sn.value }}</div>
        <div class="message_text1">订单号：{{ orderData.order_sn }}</div>
      </template>
    </new-dialog>

  </div>
</template>
<script>
import newHeader from "@/components/newHeader";
import newDialog from '@/components/newDialog';

import Input from "@/components/input";
import { getConfig_api } from "@/api/deal";
import { shipments, sharesChange_api } from "@/api/alteration";
import { Toast } from "vant";
export default {
  data() {
    return {
      // 表单类型
      isAlteration: true,
      //   地址选择
      address: false,
      // 放点击
      submitState: true,
      headerConfig: {
        show: true,
        title: "信息填写",
        showKeufu: true,
      },
      dialogConfig: {
        showDialog: false,
        cancelText: '考虑考虑',
        confirmText: '确认',
      },
      //   表单数据
      alterationFrom: {
        s_order_sn: {
          value: "",
          title: "寄送单号",
          inputStatus: 1,
          text: "请输入寄送单号",
          ladelWidth: true
        },
        s_company: {
          value: "",
          title: "物流公司",
          inputStatus: 1,
          text: "请输入物流公司",
          ladelWidth: true,
          disabled: true
        },
        s_message: {
          value: "",
          title: "备注信息",
          text: "请输入备注信息",
          ladelWidth: true,
          textarea: true
        }
      },
      shipmentsData: {},
      shipments: {},
      configData: {},
      orderData: {}
    };
  },
  components: {
    newDialog,
    newHeader,
    Input
  },
  created() {
    document.title = "信息填写";
    this.getSeting();
    // 订单信息
    if (JSON.parse(localStorage.getItem("orderData"))) {
      this.orderData = JSON.parse(localStorage.getItem("orderData"));
    }
    // 选择地址
    if (JSON.parse(localStorage.getItem("address"))) {
      this.address = JSON.parse(localStorage.getItem("address"));
    }
    // 物流信息
    if (JSON.parse(localStorage.getItem("shipments"))) {
      this.alterationFrom = JSON.parse(localStorage.getItem("shipments"));
      this.shipmentsBtn();
    }
  },
  methods: {
    // 获取系统配置
    getSeting() {
      let data = {
        config: ["shares_sales_receiver_address", "shares_sales_receiver", "shares_sales_receiver_mobile"]
      };
      getConfig_api(data).then(res => {
        this.configData = res.data;
      });
    },
    // 确认数据提交
    dialogConfirmChange() {
      if (!this.submitState) return false;
      let informationData = JSON.parse(localStorage.getItem("audit_info"));
      informationData.forEach(e => {
        e.img = e.img[0].url;
      });
      let data = {
        audit_info: informationData,
        audit_remark: {
          remark: this.alterationFrom.s_message.value
        },
        shipments_info: {
          LogisticCode: this.alterationFrom.s_order_sn.value,
          Shipper: this.shipmentsData.Shippers ? this.shipmentsData.Shippers[0].ShipperName : '',
          ShipperCode: this.shipmentsData.Shippers ? this.shipmentsData.Shippers[0].ShipperCode : '',
        },
        order_info: [{
          order_id: this.orderData.order_id,
          order_sn: this.orderData.order_sn,
          order_amount: this.orderData.order_amount,
          goods_image: this.orderData.ordergoods.goods_image,
          goods_name: this.orderData.ordergoods.goods_name,
          add_time: this.orderData.add_time,
        }]
      };
      if (!this.verification(data)) {
        return false
      }
      this.submitState = false;
      sharesChange_api(data).then(res => {
        this.submitState = true;
        if (res.code == 0) {
          localStorage.removeItem("shipments");
          localStorage.removeItem("orderData");
          localStorage.removeItem("audit_info");
          this.$router.push(`./nextAuditNew?id=${res.data.audit_id}`);
        } else {
          Toast({
            message: res.error,
            icon: "info",
            duration: 1000
          });
        }
      });
    },
    // 表单验证
    verification(data) {
      console.log(data, "data")
      for (var i in data.shipments_info) {
        if (!data.shipments_info[i]) {
          Toast({
            message: "请填写寄送信息",
            icon: "info",
            duration: 1000
          });
          return false;
        }
      }
      return true
    },
    orderBtn() {
      // 缓存寄送信息
      localStorage.setItem("shipments", JSON.stringify(this.alterationFrom))
      // 选择订单
      this.$router.push("./orderDiscount");
    },
    // 数据提交框
    nextBtnBtn() {

      this.dialogConfig.showDialog = true
    },
    // 地址选择
    selectBtn() {
      localStorage.setItem("shipments", JSON.stringify(this.alterationFrom));
      this.$router.push("../../address");
    },
    shipmentsBtn() {
      let data = {
        LogisticCode: this.alterationFrom.s_order_sn.value
      };
      shipments(data).then(res => {
        if (res.code == 0) {
          this.shipmentsData = res.data;
          this.alterationFrom.s_company.value =
            res.data.Shippers[0].ShipperName;
          this.alterationFrom.s_company.disabled = false;
          console.log(this.alterationFrom.s_company.value);
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.i_page {
  box-sizing: border-box;
  width: 100%;
  background-color: #f4f4f4;
}

.price_info {
  display: flex;
  font-size: 26px;
  justify-content: space-between;
  margin-left: 52px;
  margin: 30px 0 38px 52px;

  .order_btn {
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 26px;
    color: #0091FF;
    margin-right: 25px;
  }
}

.submit_btn_i {
  width: 450px;
  height: 80px;
  background: linear-gradient(180deg, #252324 0%, #3C3633 100%), #2F2B2B;
  border-radius: 12px 12px 12px 12px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 28px;
  color: #FFFFFF;
  text-align: center !important;
  line-height: 80px;
  margin: 50px 70px 0;
  display: inline-block;
}


.order_discoun {
  width: 600px;
  height: 160px;
  background: #F6F6F6;
  border-radius: 10px 10px 10px 10px;
  padding: 20px 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  .status_text {
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 24px;
    color: #0091FF;
    margin-top: 30px;
  }

  .discoun {
    display: flex;
    justify-content: space-between;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: rgba(26, 26, 26, 0.8);

    .text_info {
      margin-left: 13px;

      div {
        line-height: 30px;
      }

      .title {
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 22px;
        color: #1A1A1A;
      }
    }

    .discoun_img {
      width: 121px;
      height: 121px;
      border-radius: 6px 6px 6px 6px;
      background-color: skyblue;
    }
  }
}



.i_content {
  background-color: #f4f4f4;
  width: 100%;
  padding: 35px;
  box-sizing: border-box;
  min-height: calc(100vh - 92px);
}

.i_from_box {
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
  padding: 30px;
}

.i_from {
  width: 600px;
  margin: 0 auto;
  box-sizing: border-box;
}

.addBtn {
  text-align: right;
  font-size: 28px;
  color: #0038ff;
  margin: 40px 0 60px;
}

.nextBtn_a {
  width: 300px;
  height: 80px;
  background: #f1f1f1;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  text-align: center;
  line-height: 80px;
  font-size: 30px;
  color: #1a1a1a;
  margin: 50px auto 0;
}

.i_from_item {
  padding-bottom: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
}

.i_from_item input {
  color: #1a1a1a;
}

.address_top {
  background-color: #fff;
  font-size: 30px;
  color: #1a1a1a;
  padding: 45px;
  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

.a_item {
  display: flex;
  margin-bottom: 30px;
}

.address_t {
  width: 220px;
  font-size: 30px;
  color: #1a1a1a;
}

.address_t_1 {
  width: 140px;
  font-size: 30px;
  color: #1a1a1a;
}

.comfimBtn {
  font-size: 28px;
  color: #0038ff;
  margin-left: 10px;
  position: absolute;
  right: -10px;
  top: 20px;
}

.address_p {
  margin-top: 30px;
}

.address_title {
  font-size: 30px;
  color: #fff;
  margin-left: 10px;
}

.address_box {
  width: 380px;
  height: 170px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid #eee;
  line-height: 170px;
  text-align: center;
  font-size: 30px;
  color: #0038ff;
  margin: 36px 0;
}

.address_box1 {
  width: 380px;
  height: 170px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid #eee;
  font-size: 24px;
  line-height: 40px;
  color: #0038ff;
  margin: 36px 0;
  padding: 12px 26px;
  box-sizing: border-box;
}

.dig_box {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
}

.item_box {
  width: 610px;
  height: 388px;
  background-image: url("../../assets/Slice366@2x.png");
  background-size: 100% 100%;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  margin: 400px auto 0;
  position: relative;
  padding: 40px 61px;
  opacity: 1;
  box-sizing: border-box;
}

.item_box img {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.item_box div {
  font-size: 30px;
  color: #1a1a1a;
  margin-bottom: 33px;
}

.item_box .dig_title {
  text-align: center;
  font-size: 38px;
  font-weight: 800;
  margin-top: 20px;
}

.item_box .dig_confim {
  font-size: 32px;
  color: #0038ff;
  text-align: center;
  margin-top: 40px;
}

.van-toast {
  width: 300px;
  min-height: 120px;
  line-height: 40px;
}
</style>
<style scoped>
.i_from_item>>>.ladelWidth {
  width: 130px;
}
.new_dialog>>>.message_text1{
  color: rgba(0,0,0,0.8);
  font-weight: bold;
}
</style>
